export function openMenu(menu){
	menu.slideDown(500, function(){
		menu.addClass("open cur");
		console.log("open");
	});
}


export function closeMenu(menu){
	menu.slideUp(500, function(){
		menu.removeClass("open cur");
	});
}


export function openMenuBig(btn, menu, wrap){
	console.log("open");
    wrap.empty();
  	menu.clone().appendTo(wrap).fadeIn(400, function(){ btn.addClass("open cur"); });
}

export function closeMenuBig( btn , wrap ){
	btn.removeClass("open cur");
	wrap.empty();

}

export function goBackTop(){
	window.scrollTo(0, 0);
}